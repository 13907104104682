<template>
    <div>
        <div class="pb-1">
            <b-input-group>
                <b-input type="search" :placeholder="$t('generally.tables.search') + '...'" v-model="search" @keyup="parseLog"/>
                <b-select v-model="searchType" @change="parseLog">
                    <b-select-option value="text">Meldung</b-select-option>
                    <b-select-option value="time">Zeit</b-select-option>
                </b-select>
            </b-input-group>
        </div>
        <div class="log-container">
            <log-entry v-for="(entry, index) in logs" :key="index" :data="entry" :time-format="timeFormat"/>
            <span v-if="logs.length === 0">{{ $t('generally.noEntries') }}</span>
        </div>
        <div>
            {{ logs.length }} {{ $t('generally.entries') }}
        </div>
    </div>
</template>

<script>
import LogEntry from "./logEntry";
export default {
    name: "logContainer",
    components: {LogEntry},
    props: {
        data: {
            type: [Array, String, undefined]
        },
        maxHeight: {
            type: [Number, undefined]
        }
    },
    data: () => ({
        search: "",
        searchType: "text",
        timeFormat: 'DD.MM.YYYY HH:mm:ss',
        logs: []
    }),
    mounted() {
        this.parseLog();
    },
    methods: {
        parseLog(){
            let response = this.data.split(/\n/);
            response.pop();

            for (const responseKey in response) {
                response[responseKey] = [
                    response[responseKey].substr(0, 24),
                    response[responseKey].substr(26)
                ];
            }

            response = response.reverse();

            if(this.search !== ""){
                let tmpSearch = this.search.toLowerCase();
                console.log(tmpSearch);
                if(this.searchType === "text") {
                    response = response.filter(item => item[1].toLowerCase().match(tmpSearch));
                } else {
                    response = response.filter(item => (this.$moment(item[0]).format(this.timeFormat)).toLowerCase().match(tmpSearch));
                }
            }

            this.logs = response;
            return response;
        }
    }
};
</script>

<style scoped>

</style>
