<template>
    <div class="log-entry">
        <div class="log-time">
            {{ $moment(data[0]).format(timeFormat) }} >
        </div>
        <div class="log-message">
            {{ data[1] }}
        </div>
    </div>
</template>

<script>
export default {
    name: "logEntry",
    props: {
        data: {
            type: Array
        },
        timeFormat: {
            type: String,
            default: 'DD.MM.YYYY HH:mm:ss'
        }
    }
};
</script>

<style scoped>

</style>
