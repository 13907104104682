<template>
    <div>
        <CCard>
            <CCardBody>
                <b-row>
                    <b-col class="text-right">
                        <b-button variant="orange" class="mr-1" v-b-modal.pool-detail-vm-logs><font-awesome-icon icon="align-left"/> {{ $t('buttons.showLog') }}</b-button>
                        <b-button @click.prevent="$router.go(-1)">{{ $t('buttons.prev') }}</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" md="6" lg="4" xl="3">
                        <h4>{{ $t('pages.vdi.pools.headers.general') }}</h4>
                        <b-table-simple responsive striped small>
                            <b-tbody>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.pools.form.poolId') }}:</b-td>
                                    <b-td>{{ data.id }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.pools.form.poolName', { language: $t('generally.languages.de') }) }}:</b-td>
                                    <b-td>{{ data.publicName[locale] }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.pools.form.vmName') }}:</b-td>
                                    <b-td>{{ data.name }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.pools.form.type') }}:</b-td>
                                    <b-td v-if="data.type">{{ $t('pages.vdi.pools.values.type.' + data.type) }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.pools.form.disabled') }}:</b-td>
                                    <b-td>
                                        <font-awesome-icon :class="data.disabled ? 'text-danger' : 'text-success'" :icon="data.disabled ? 'times' : 'check'"/>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.pools.form.public') }}:</b-td>
                                    <b-td>
                                        <font-awesome-icon :class="data.public ? 'text-success' : 'text-danger'" :icon="data.public ? 'check' : 'times'"/>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.pools.form.owner') }}:</b-td>
                                    <b-td><span v-if="data.owner">{{ data.owner.firstName }} {{ data.owner.lastName }}</span></b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.pools.form.created') }}:</b-td>
                                    <b-td>{{ $moment(data.createdAt).format('DD.MM.YYYY HH:mm') }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.pools.form.image') }}:</b-td>
                                    <b-td>{{ data.vm }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.pools.form.folder') }}:</b-td>
                                    <b-td>{{ data.vCenterFolderRef }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.pools.form.datastore') }}:</b-td>
                                    <b-td>{{ data.datastore }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                    <b-col sm="12" md="6" lg="4" xl="3">
                        <h4>{{ $t('pages.vdi.pools.headers.poolSettings') }}</h4>
                        <b-table-simple responsive striped small>
                            <b-tbody>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.pools.form.max') }}:</b-td>
                                    <b-td>{{ data.max }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.pools.form.min') }}:</b-td>
                                    <b-td>{{ data.min }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.pools.form.domain') }}:</b-td>
                                    <b-td>
                                        <font-awesome-icon :class="data.domain ? 'text-success' : 'text-danger'" :icon="data.domain ? 'check' : 'times'"/>
                                    </b-td>
                                </b-tr>
                                <b-tr v-if="data.type === 'dynamic'">
                                    <b-td>{{ $t('pages.vdi.pools.form.destroy') }}:</b-td>
                                    <b-td>{{ data.destroyingTime }} h</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.vdi.pools.form.scriptCode') }}:</b-td>
                                    <b-td>
                                        <a href="#" @click.prevent.stop="() => ({})" v-b-modal.pool-detail-scriptCode v-if="data.scriptCode">{{ $t('buttons.show') }}</a>
                                        <span v-else>-</span>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
                <b-modal
                    id="pool-detail-scriptCode"
                    :title="$t('pages.vdi.pools.form.scriptCode')"
                >
                    <vue-codeditor style="font-size: 14px" v-model="data.scriptCode" mode="powershell" theme="monokai"/>
                </b-modal>
                <hr>
                <h4>{{ $t('pages.vdi.pools.headers.poolMachines') }}</h4>
                <b-row>
                    <b-col sm="6" md="8" xl="8" class="my-1">
                        <b-button :disabled="selectedItems.filter(i => i.power_state === 'POWERED_ON').length === 0" size="sm" class="mr-1" @click="sendMultiAction('shutdown')">Herunterfahren</b-button>
                        <b-button :disabled="selectedItems.filter(i => i.power_state === 'POWERED_OFF').length === 0" variant="danger" class="mr-1" size="sm" @click="sendMultiAction('delete')">Löschen</b-button>
                    </b-col>
                    <b-col sm="6" md="4" xl="4" class="my-1">
                        <b-form-group
                            label-for="filter-input"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                        >
                            <b-input-group size="sm">
                                <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    :placeholder="$t('generally.tables.tipToSearch')"
                                ></b-form-input>

                                <b-input-group-append>
                                    <b-button :disabled="!filter" @click="filter = ''">{{ $t('generally.tables.clear') }}</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-table
                            :items="data.vms"
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :filter="filter"
                            @filtered="onFiltered"
                            :empty-text="$t('generally.tables.emptyText')"
                            :empty-filtered-text="$t('generally.tables.emptyFilteredText')"
                            stacked="md"
                            show-empty
                            striped
                            selectable
                            select-mode="multi"
                            ref="vmTable"
                            class="bg-white"
                            @row-selected="onSelected"
                        >
                            <template #head(selected)="{}">
                                <template v-if="selectAll">
                                    <span aria-hidden="true" href="#" @click.prevent="checkAll()"><font-awesome-icon :icon="['far', 'check-square']"/></span>
                                </template>
                                <template v-else>
                                    <span aria-hidden="true" href="#" @click.prevent="checkAll()"><font-awesome-icon :icon="['far', 'square']"/></span>
                                </template>
                            </template>
                            <template #cell(selected)="{ rowSelected }">
                                <template v-if="rowSelected">
                                    <span aria-hidden="true"><font-awesome-icon :icon="['far', 'check-square']"/></span>
                                    <span class="sr-only">Selected</span>
                                </template>
                                <template v-else>
                                    <span aria-hidden="true"><font-awesome-icon :icon="['far', 'square']"/></span>
                                    <span class="sr-only">Not selected</span>
                                </template>
                            </template>
                            <template #cell(cpu_count)="row">
                                {{ row.item.cpu_count }}
                            </template>
                            <template #cell(memory_size_MiB)="row">
                                {{ row.item.memory_size_MiB > 1024 ? Math.round(row.item.memory_size_MiB / 1024) : row.item.memory_size_MiB }} {{ row.item.memory_size_MiB > 1024 ? 'GiB' : 'MiB' }}
                            </template>
                            <template #cell(power_state)="row">
                                <font-awesome-icon :class="'text-' + getVmState(row.item, 'color')" :icon="getVmState(row.item, 'icon')"/>
                            </template>
                            <template #cell(actions)="row">
                                <b-button variant="primary" class="btn-xs mb-0 ml-1" :title="$t('buttons.show')" @click="loadSingle(row.item.vm)">
                                    <font-awesome-icon icon="eye"/>
                                </b-button>
                                <b-button v-show="row.item.power_state === 'POWERED_OFF'" variant="success" class="btn-xs mb-0 ml-1" :title="$t('buttons.start')" @click="openActionModal(row.item, 'start')">
                                    <font-awesome-icon icon="play"/>
                                </b-button>
                                <b-button v-show="row.item.power_state === 'POWERED_ON'" variant="info" class="btn-xs mb-0 ml-1" :title="$t('buttons.reboot')" @click="openActionModal(row.item, 'reboot')">
                                    <font-awesome-icon icon="undo"/>
                                </b-button>
                                <b-button v-show="row.item.power_state === 'POWERED_ON'" variant="purple" class="btn-xs mb-0 ml-1" :title="$t('buttons.shutdown')" @click="openActionModal(row.item, 'shutdown')">
                                    <font-awesome-icon icon="power-off"/>
                                </b-button>
                                <b-button v-show="row.item.power_state === 'POWERED_ON'" variant="warning" class="btn-xs mb-0 ml-1" :title="$t('buttons.reset')" @click="openActionModal(row.item, 'reset')">
                                    <font-awesome-icon icon="redo"/>
                                </b-button>
                                <b-button v-show="row.item.power_state === 'POWERED_ON'" variant="danger" class="btn-xs mb-0 ml-1" :title="$t('buttons.hardStop')" @click="openActionModal(row.item, 'stop')">
                                    <font-awesome-icon icon="stop"/>
                                </b-button>
                                <b-button v-show="row.item.power_state === 'POWERED_OFF'" variant="danger" class="btn-xs mb-0 ml-1" :title="$t('buttons.delete')" @click="openActionModal(row.item, 'delete')">
                                    <font-awesome-icon icon="trash"/>
                                </b-button>
                            </template>
                            <template #row-details="row">
                                <b-card>
                                    <ul>
                                        <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                                    </ul>
                                </b-card>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="right"
                            size="sm"
                            class="my-0"
                        ></b-pagination>
                    </b-col>
                </b-row>
                <hr>
                <user-to-resource-list :data="data.users" attr="users_pools"/>
            </CCardBody>
        </CCard>
        <b-modal id="pool-detail-vm-detail" title="JSON" size="xl">
            <vue-json-pretty :path="'res'" :data="json"></vue-json-pretty>
        </b-modal>
        <b-modal id="pool-detail-vm-logs" :title="$t('pages.vdi.pools.headers.logs')" size="xl" ok-only footer-class="justify-content-end">
            <log-container :data="data.log"/>
        </b-modal>
        <b-modal
            id="action-vm-modal"
            :title="$t('pages.vdi.vm.modal.action.title')"
            :cancel-title="$t('buttons.no')"
            :ok-title="$t('buttons.yes')"
            ok-variant="danger"
            @ok="onSubmitAction"
        >
            <span v-html="$t('pages.vdi.vm.modal.action.message', { action: $t('buttons.' + selectedItem.action), vm: selectedItem.item.name})"></span>
        </b-modal>
        <b-modal
            id="multi-action-vm-modal"
            :title="$t('pages.vdi.vm.modal.action.title')"
            :cancel-title="$t('buttons.no')"
            :ok-title="$t('buttons.yes')"
            ok-variant="danger"
            @ok="onSubmitMultiAction"
        >
            <span v-html="$t('pages.vdi.vm.modal.action.message', { action: $t('buttons.' + selectedAction), vm: selectedVMs})"></span>
        </b-modal>
    </div>
</template>

<script>
import api from "../../../services/api";
import vmService from "../../../services/vmService";
import VueJsonPretty from "vue-json-pretty";
import 'vue-json-pretty/lib/styles.css';
import UserToResourceList from "../../../components/UserToResourceList";
import LogContainer from "../../../components/log/logContainer";
import mainServices from "../../../services/mainServices";


export default {
    name: "Detail",
    data: () => ({
        data: {publicName: {"de":"","en":""}, vms: []},
        fields: [
            {key: 'selected', label: '', sortable: false},
            {key: 'name', label: 'Name', sortable: true, sortDirection: 'asc'},
            {key: 'cpu_count', label: 'CPU', sortable: true, sortDirection: 'asc'},
            {key: 'memory_size_MiB', label: 'RAM', sortable: true, sortDirection: 'asc'},
            {key: 'power_state', label: 'Status', sortable: true},
            {key: 'actions', label: 'Aktionen'}
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [5, 10, 15, 25, 50],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        selectAll: false,
        selectedItems: [],
        selectedAction: "start",
        selectedVMs: "",
        items: [],
        json: '',
        selectedItem: {
            item: '',
            action: 'start'
        },
        intervalLoad: null,
        locale: 'de',
    }),
    watch: {
        '$i18n.locale': function (newVal) {
            this.locale = newVal;
        }
    },
    components: {
        LogContainer,
        UserToResourceList,
        VueJsonPretty
    },
    mounted() {
        this.loadData();
        this.intervalLoad = setInterval(this.loadVMs, 10000)
        this.locale = mainServices.getLocale();
    },
    methods: {
        getLocale() {
            return localStorage.getItem("locale");
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        loadData() {
            api().get("vCenter/pools/" + this.$route.params.id).then(response => {
                response.data.publicName = JSON.parse(response.data.publicName);
                this.data = response.data;
                if(response.data.image && response.data.image.vCenterImageRef) {
                    vmService.get(response.data.image.vCenterImageRef).then(response => {
                        this.data.imageData = response.data;
                    }).catch();
                }
            }).catch();
        },
        loadVMs() {
            api().get("vCenter/pools/" + this.$route.params.id).then(response => {
                this.data.vms = response.data.vms;
                this.data.publicName = JSON.parse(response.data.publicName);
            }).catch();
        },
        loadSingle(id) {
            api().get("vCenter/vms/" + id).then(res => {
                this.json = res.data;
                this.$bvModal.show("pool-detail-vm-detail");
            }).catch();
        },
        checkAll(){
            this.selectAll = !this.selectAll;
            if(this.selectAll){
                this.$refs.poolTable.selectAllRows()
                this.selectedItems = this.data.vms;
            } else {
                this.$refs.poolTable.clearSelected()
                this.selectedItems = [];
            }
        },
        onSelected(items){
            this.selectAll = this.data.vms.length === items.length;
            this.selectedItems = items;
        },
        getVmState(item, attr){
            return vmService.getState(item, attr);
        },
        openActionModal(item, action){
            this.selectedItem.action = action;
            this.selectedItem.item = item;
            this.$bvModal.show('action-vm-modal')
        },
        sendMultiAction(action){
            this.selectedAction = action;
            this.selectedVMs = this.selectedItems.map(item => item.name).join(', ');
            this.$bvModal.show('multi-action-vm-modal')
        },
        onSubmitAction(){
            if(this.selectedItem.action === 'delete') {
                vmService.delete(this.selectedItem.item.vm, this.selectedItem.action).then(response => {
                    this.$toastr.s(this.$t('pages.vdi.vm.messages.success.sendAction', { action: this.$t('buttons.' + this.selectedItem.action), vm: this.selectedItem.item.name}));
                }).catch(error => {
                    this.errorHandler(error)
                });
            }
            else {
                vmService.sendPowerAction(this.selectedItem.item.vm, this.selectedItem.action).then(response => {
                    this.$toastr.s(this.$t('pages.vdi.vm.messages.success.sendAction', { action: this.$t('buttons.' + this.selectedItem.action), vm: this.selectedItem.item.name}));
                }).catch(error => {
                    this.errorHandler(error)
                });
            }
            this.$bvModal.hide('action-vm-modal')
        },
        onSubmitMultiAction(){
            for (const item of this.selectedItems) {
                if(this.selectedAction === 'delete') {
                    vmService.delete(item.vm, this.selectedAction).then(response => {
                        this.$toastr.s(this.$t('pages.vdi.vm.messages.success.sendAction', { action: this.$t('buttons.' + this.selectedAction), vm: item.name}));
                    }).catch(error => {
                        this.errorHandler(error)
                    });
                }
                else {
                    vmService.sendPowerAction(item.vm, this.selectedAction).then(response => {
                        this.$toastr.s(this.$t('pages.vdi.vm.messages.success.sendAction', { action: this.$t('buttons.' + this.selectedAction), vm: item.name}));
                    }).catch(error => {
                        this.errorHandler(error)
                    });
                }
            }
            this.$bvModal.hide('multi-action-vm-modal')
        }
    },
    beforeDestroy() {
        clearInterval(this.intervalLoad)
    }
};
</script>

<style scoped>

</style>
